import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const routes = [{
  path: '/',
  redirect: "/login"
}, {
  path: '/login',
  name: 'login',
  meta: {
    isTabbar: false
  },
  component: () => import('../views/login/index.vue')
},
{
  path: '/home',
  name: 'home',
  meta: {
    tabbarNum: 0,
    isTabbar: true,
    keepAlive: true
  },
  component: () => import('../views/home/index.vue')
},
{
  path: '/course',
  name: 'course',
  meta: {
    tabbarNum: 1,
    isTabbar: false,
    keepAlive: true
  },
  component: () => import('../views/course/index.vue')
},
{
  path: '/my',
  name: 'my',
  meta: {
    tabbarNum: 2,
    isTabbar: true
  },
  component: () => import('../views/my/index.vue')
},
{
  path: '/accountinformation',
  name: 'accountinformation',
  meta: {
    isTabbar: false
  },
  component: () => import('../views/user/accountinformation.vue')
},
{
  path: '/checkin',
  name: 'checkin',
  meta: {
    isTabbar: false,
  },
  component: () => import('../views/checkin/index.vue')
},
{
  path: '/misslesson',
  name: 'misslesson',
  meta: {
    isTabbar: false
  },
  component: () => import('../views/misslesson/index.vue')
},
{
  path: '/personalevaluation',
  name: 'personalevaluation',
  meta: {
    isTabbar: false
  },
  component: () => import('../views/comment/personalevaluation/index.vue')
},
{
  path: '/comment',
  name: 'comment',
  meta: {
    isTabbar: false
  },
  component: () => import('../views/comment/comment.vue'),
  children: [{
    path: '/comment/student',
    name: 'commentStudent',
    meta: {
      active: 0
    },
    component: () => import('../views/comment/student/index.vue')
  }, {
    path: '/comment/class',
    name: 'commentClass',
    meta: {
      active: 1
    },
    component: () => import('../views/comment/class/index.vue')
  }, {
    path: '/comment/work',
    name: 'commentWork',
    meta: {
      active: 2
    },
    component: () => import('../views/comment/work/index.vue')
  },]
},
{ path: '/ClassEvaluation', name: 'ClassEvaluation', component: () => import('../views/oldpage/ClassEvaluation.vue') },
{ path: '/UploadHomeWork', name: 'UploadHomeWork', component: () => import('../views/oldpage/UploadHomeWork.vue') },
{ path: '/LessonList', name: 'LessonList', component: () => import('../views/oldpage/LessonList.vue') },
{ path: '/CreateLesson', name: 'CreateLesson', component: () => import('../views/oldpage/CreateLesson.vue') },
{ path: '/CourseList', name: 'CourseList', component: () => import('../views/oldpage/CourseList.vue') },
{ path: '/rollCall', name: 'rollCall', component: () => import('../views/oldpage/Rollcall.vue') },
{ path: '/PersonalEvaluation', name: 'PersonalEvaluation', component: () => import('../views/oldpage/PersonalEvaluation.vue') },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router