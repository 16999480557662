import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import vant from 'vant'
import 'amfe-flexible'
import 'vant/lib/index.css';
import '@/css/index.less';
import "@/vant/index.js"
import dispatchEventStroage from './utils/watchLocalStorage'

const app = createApp(App)
    .use(router)
    .use(vant)
    .use(dispatchEventStroage)
    .mount('#app');