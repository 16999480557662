import {
    createApp
} from 'vue';
const app = createApp();
import {
    Loading,
    Form,
    Field,
    Toast,
    Button,
    Search,
    Swipe,
    SwipeItem,
    Icon,
    Tag,
    List,
    Cell,
    DropdownMenu,
    DropdownItem,
    Tabbar,
    TabbarItem,
    Col,
    Row,
    NavBar,
    Step,
    Steps,
    CellGroup,
    Rate,
    Radio,
    RadioGroup,
    Checkbox,
    CheckboxGroup,
    Uploader,
    DatetimePicker,
    Popup,
    Sticky,
    Empty,
    Dialog,
    Overlay,
    Picker,
    PullRefresh,
    Collapse,
    CollapseItem,
    ImagePreview,
    Calendar,
    Tab,
    Tabs,
} from 'vant';

app.use(DropdownMenu);
app.use(DropdownItem);
app.use(List);
app.use(Tag);
app.use(Icon);
app.use(Swipe);
app.use(SwipeItem);
app.use(Search);
app.use(Button);
app.use(Cell);
app.use(Form);
app.use(Field);
app.use(Loading);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Col);
app.use(Row);
app.use(NavBar);
app.use(Step);
app.use(Steps);
app.use(Cell);
app.use(CellGroup);
app.use(Rate);
app.use(Radio);
app.use(RadioGroup);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Uploader);
app.use(DatetimePicker);
app.use(Popup);
app.use(Sticky);
app.use(Empty);
app.use(Overlay);
app.use(Picker);
app.use(PullRefresh);
app.use(Collapse);
app.use(CollapseItem);
app.use(Tab);
app.use(Tabs);
app.use(ImagePreview);
app.use(Calendar);
app.use(Dialog);
app.use(Toast);
Toast.setDefaultOptions({ duration: 2000 });